var theme = {
    colors: {
        primary: "#E83D56",
        primary_light: "#FC6C8C",
        primary_ulight: "#FFF1F3",
        accent: "#84C9BD",
        accent_ulight: "#EFF8F7",
        accent_darker: "#52B3A1",
        accent_dark: "#1D423B",
        gunmetal: "#224552",
        telegram: "#389CE9",
        messenger: "#037AF7",
    },
    fontFamily: {
        primary: "Poppins, sans-serif",
        secondary: "Quicksand, sans-serif",
    },
    shadows: {
        "up-3": "0px -1px 1px -1px rgba(8, 11, 14, 0.06), 0 -6px 6px -1px rgba(8, 11, 14, 0.1)",
        "top-3":
            "rgba(8, 11, 14, 0.06) 0px -1px 1px -1px, rgba(8, 11, 14, 0.1) 0px -4px 6px -1px, rgba(8, 11, 14, 0.1) 0px 4px 6px -1px",
    },
    rounded: {
        upOnly: "12px 12px 0 0",
        xxl: "40px",
        "20px": "20px",
    },
    transition: {
        fast: "all 0.2s ease-in-out",
    },
    textSize: {
        size: {
            display4: "70px",
            display5: "150px",
            display6: "230px",
        },
        height: {
            display4: "82px",
        },
    },
}

var url = {
    home: "/",
    how: "/come-funziona/",
    orderNow: "/come-funziona/#ordina_ora",
    business: "/business/",
    why: "/obiettivi/",
    team: "/team/",
    blog: "/blog/",
    faq: "/faq/",
    faqSupp: "/faq/faq-locali/",
    faqUser: "/faq/faq-clienti/",
    legalNotice: "/note-legali/",
    termsConditions: "/termini-condizioni/",
}

var config = {
    theme: theme,
    url: url,
}

module.exports = config
