/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, ogImage, pathname }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
            }
        `
    )

    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? `${defaultTitle}: %s` : null}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:site_name`,
                    content: `Squiseat`,
                },
                {
                    property: `og:url`,
                    content: `${site?.siteMetadata?.siteUrl}/${pathname}`,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: ogImage || `https://bit.ly/foto-banner-social-di-squiseat-con-pulsante-ordina-ora`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:site`,
                    content: `@squiseat`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata?.author || ``,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `ahrefs-site-verification`,
                    content: `230f8e09ced62a708f662be673f78fd691064568c9b0976f06f0dcb1f0d5a970`,
                },
                {
                    name: `facebook-domain-verification`,
                    content: `5pmsi5u12dchw0u8l5r51aqxrsdvui`,
                },
            ].concat(meta)}
        >
            <script id="mcjs">
                {`!function(c,h,i,m,p)
                {
                    ((m = c.createElement(h)),
                    (p = c.getElementsByTagName(h)[0]),
                    (m.async = 1),
                    (m.src = i),
                    p.parentNode.insertBefore(m, p))
                }
                (document,"script","https://chimpstatic.com/mcjs-connected/js/users/5b1c31f5e6a30c8a7f20fe19a/71b4e022968fc76c36b3a58c6.js");`}
            </script>
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `it`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
