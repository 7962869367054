import { Text, currentDevice, Button, Icon } from "atomize"
import { Col, Div, Row } from "atomize"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import BoldText from "./BoldText"

let config = require("../settings/config")

const url = config.url

const Header = props => {
    const [showSide, setShowSide] = useState(false)
    const [windowSize, setWindowSize] = useState(null)
    const [windowLocation, setWindowLocation] = useState("/how/")

    const [scrollTarget, setScrollTarget] = useState(false)

    useEffect(() => {
        setWindowLocation(window.location.pathname.split("/")[1])
        setScrollTarget(false)
    }, [])

    useEffect(() => {
        switch (windowLocation) {
            case "come-funziona":
                document.getElementById("Come funziona").style.fontWeight = "900"
                break
            case "obiettivi":
                document.getElementById("Perché Squiseat").style.fontWeight = "900"
                break
            case "business":
                document.getElementById("Hai un'attività?").style.fontWeight = "900"
                break
            case "team":
                document.getElementById("Team").style.fontWeight = "900"
                break
            case "blog":
                document.getElementById("Blog").style.fontWeight = "900"
                break
            default:
                break
        }
    })

    useEffect(
        function setupListener() {
            const size = currentDevice()
            size === "xs" || size === "sm" || size === "md" ? setWindowSize("mobile") : setWindowSize("desktop")

            function handleResize() {
                const size = currentDevice()
                size === "xs" || size === "sm" || size === "md" ? setWindowSize("mobile") : setWindowSize("desktop")
                console.log(currentDevice())
            }

            function handleScroll() {
                if (windowLocation === url.home) {
                    let currentScrollPos = window.pageYOffset || document.documentElement.scrollTop
                    currentScrollPos > 500 ? setScrollTarget(true) : scrollTarget && setScrollTarget(false)
                } else if (windowLocation === url.why || windowLocation === url.legalNotice) setScrollTarget(true)
            }

            window.addEventListener("resize", handleResize)
            window.addEventListener("scroll", handleScroll)

            return function cleanupListener() {
                window.removeEventListener("resize", handleResize)
                window.removeEventListener("scroll", handleScroll)
            }
        },
        [windowLocation, scrollTarget]
    )

    let navigation

    const NavEntry = props => {
        return (
            <Div p="1rem 1.5rem" m="auto 0">
                <Link to={props.link} title={props.title}>
                    <Text
                        id={props.text}
                        className="nav_link"
                        textColor={props.textColor}
                        textSize="subheader"
                        onClick={() => props.onClick}
                    >
                        {props.text}
                    </Text>
                </Link>
            </Div>
        )
    }

    const NavLinks = props => {
        return (
            <>
                <NavEntry
                    link={url.how}
                    text="Come funziona"
                    title="Ordina ora | Squiseat"
                    textColor={props.textColor}
                    onClick={props.onClick}
                />
                <NavEntry
                    link={url.why}
                    text="Perché Squiseat"
                    title="I nostri obiettivi | Squiseat"
                    textColor={props.textColor}
                    onClick={props.onClick}
                />
                <NavEntry
                    link={url.business}
                    text="Hai un'attività?"
                    title="Hai un'attività? Collabora con noi | Squiseat"
                    textColor={props.textColor}
                    onClick={props.onClick}
                />
                <NavEntry
                    link={url.team}
                    text="Team"
                    title="Chi siamo? | Squiseat"
                    textColor={props.textColor}
                    onClick={props.onClick}
                />
                <NavEntry
                    link={url.blog}
                    text="Blog"
                    title="Blog | Squiseat"
                    textColor={props.textColor}
                    onClick={props.onClick}
                />
            </>
        )
    }

    if (windowSize === "mobile")
        navigation = (
            <>
                <Col size="8" style={{ margin: "auto" }} d="flex" justify="center">
                    <AnchorLink to={url.how} title="Ecco come ordinare! | Squiseat">
                        <Button
                            id="order_now"
                            m="0.5rem 0"
                            h={{ xs: "3rem", lg: "4rem" }}
                            bottom={!scrollTarget ? "-5rem" : "0"}
                            p={{ x: "1.5rem" }}
                            bg="white"
                            border="2px solid"
                            borderColor="white"
                            rounded="circle"
                        >
                            <Text textColor="primary" textSize={{ xs: "subheader", md: "title" }}>
                                Scarica l'app
                            </Text>
                        </Button>
                    </AnchorLink>
                </Col>
                <Col size="2" d="flex" justify="flex-end" p="0" style={{ margin: "auto" }}>
                    <Button bg="transparent" onClick={() => setShowSide(!showSide)}>
                        <Icon
                            name={!showSide ? "Menu" : "Cross"}
                            transition
                            color={windowLocation !== "" ? "gunmetal" : "white"}
                            size="40px"
                        />
                    </Button>
                </Col>
                <Div pos="relative" w="100vw" h={!showSide ? "0" : "20rem"} transition bg="white" zIndex="0">
                    <NavLinks textColor="gunmetal" onClick={() => setShowSide(false)} />
                </Div>
            </>
        )
    else
        navigation = (
            <Col size="10" d="flex">
                <NavLinks textColor={windowLocation !== "" ? "gunmetal" : "white"} />
            </Col>
        )

    const logoStyleMobile = { width: "2.5rem", margin: "auto", display: "table" }
    const logoStyleDesktop = { width: "3rem", margin: "auto", display: "table" }

    return (
        <header>
            <Div
                bg={windowLocation !== "" ? "accent_ulight" : "primary"}
                pos="fixed"
                bottom={{ xs: "0", lg: "unset" }}
                w="100vw"
                zIndex="4"
            >
                <Row p={{ x: { xs: "0rem", lg: "2rem" }, y: "0" }} m="auto">
                    <Col size={{ xs: "2", lg: "1" }} p="0.5rem" style={{ margin: "auto" }}>
                        <Link to={url.home} m="auto" title="Home page | Squiseat">
                            <Div w="fit-content" m={{ l: { xs: "0.5rem", lg: "0" } }}>
                                {windowLocation !== "" ? (
                                    <Div d="flex" align="center">
                                        <StaticImage
                                            src={"../images/squiseat-logo-ufficiale-senza-testo.png"}
                                            alt="Logo di Squiseat testo"
                                            placeholder="blurred"
                                            layout="fixed"
                                            width={35}
                                        />
                                        <Text
                                            p={{ l: "0.5rem" }}
                                            fontFamily="secondary"
                                            textSize="title"
                                            textColor="gunmetal"
                                        >
                                            squis<BoldText>eat</BoldText>
                                        </Text>
                                    </Div>
                                ) : (
                                    <Div d="flex" align="center">
                                        <StaticImage
                                            src={"../images/squiseat-logo-negativo-senza-testo.png"}
                                            alt="Logo di Squiseat negativo e senza testo"
                                            placeholder="blurred"
                                            layout="fixed"
                                            width={35}
                                        />
                                        <Text
                                            p={{ l: "0.5rem" }}
                                            fontFamily="secondary"
                                            textSize="title"
                                            textColor="white"
                                        >
                                            squis<BoldText textColor="white">eat</BoldText>
                                        </Text>
                                    </Div>
                                )}
                            </Div>
                            {/*<Text textColor="white" textAlign="center" fontFamily="secondary">
                                squis<BoldText textColor="white">eat</BoldText>
    </Text>*/}
                        </Link>
                    </Col>
                    {navigation}
                </Row>
            </Div>
        </header>
    )
}

export default Header
