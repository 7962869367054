import PropTypes from "prop-types"
import React, { memo } from "react"
import { Text } from "atomize"
import { theme } from "../settings/config"

BoldText.propTypes = {
    action: PropTypes.any,
    children: PropTypes.any,
    textColor: PropTypes.string,
}

BoldText.defaultProps = {
    textColor: theme.colors.gunmetal,
    action: null,
}

function BoldText({ children, textColor, action, ...others }) {
    return (
        <Text tag="span" textColor={textColor} textWeight="bold" onClick={action} {...others}>
            {children}
        </Text>
    )
}

export default memo(BoldText)
